<template>
   <v-container>
      <v-row>
         <v-col cols="12">

            <v-card>
               <v-card-title>
                  Testing device function
               </v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col cols="12">
                        <v-text-field
                           label="ZK Dev IP"
                           outlined single-line hide-details
                           v-model="devIP"
                        ></v-text-field>
                     </v-col>
                     <v-col cols="4">
                        <div class="ma-2">
                           <v-btn color="primary"
                              @click="sendPingRequest">
                              Send PING
                           </v-btn>
                        </div>

                        <div class="ma-2">
                           <v-btn color="primary"
                              @click="sendUnlockRequest">
                              Send Unlock
                           </v-btn>
                        </div>

                        <div class="ma-2">
                           <v-btn color="primary"
                              @click="sendReadOneUserRequest">
                              Read user
                           </v-btn>

                           <v-text-field
                              class="ml-4"
                              label="User ID"
                              style="max-width:100px;display:inline-block;"
                              outlined single-line hide-details
                              dense
                              v-model="userID"
                           ></v-text-field>
                        </div>
                        <div class="ma-2">
                           <v-btn color="primary"
                              @click="sendReadUserFaceRequest">
                              Read user face
                           </v-btn>

                           <v-text-field
                              class="ml-4"
                              label="User ID"
                              style="max-width:100px;display:inline-block;"
                              outlined single-line hide-details
                              dense
                              v-model="userID"
                           ></v-text-field>
                        </div>
                        <div class="ma-2">
                           <v-btn color="primary"
                              @click="sendReadUsersLiteRequest">
                              Read Users Simple
                           </v-btn>
                        </div>

                        <div class="ma-2">
                           <v-btn color="primary"
                              @click="sendReadUsersFullRequest">
                              Read Users Full
                           </v-btn>
                        </div>
                     </v-col>

                     <v-col cols="8" style="border: 1px solid #AAAAAA;border-radius: 1px;">
                        {{result}}
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>

         </v-col>
      </v-row>
   </v-container>
</template>

<script>
export default {
   data () {
      return {
         result: null,
         socketAskKey: new Date().getTime(),
         devIP: "0.0.0.0",
         userID: "1",
         sendTime: null,
         users: [],
      }
   },
   sockets: {
      'ping-REPLY': function (payload) {
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            this.$forceUpdate()
         }
         console.log("Call spend time: " 
            + Math.round(new Date().getTime() - this.sendTime.getTime()))
      },
      'unlock-REPLY': function (payload) {
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            this.$forceUpdate()
         }
         console.log("Call spend time: " 
            + Math.round(new Date().getTime() - this.sendTime.getTime()))
      },
      'readOneUser-REPLY': function (payload) {
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            if(this.result.faceTemplate && this.result.faceTemplate.length>0)
            { 
               this.result.faceTemplate = "X"
            }
            if(this.result.fingers){
               this.result.fingers = this.result.fingers.map(f => 
               {
                  if(f.length>0){ 
                     return "X"
                  } else {
                     return ""
                  }
               })
               // this.result.fingers.forEach(finger => {
               //    if(finger.length>0) finger="X"
               // })
            }
            this.$forceUpdate()
         }
         console.log("Call spend time: " 
            + Math.round(new Date().getTime() - this.sendTime.getTime()))
      },
      'getUserFace-REPLY': function (payload) {
         if(payload && payload.askKey == this.socketAskKey){
            console.log(payload.result)
            this.result = payload.result
            // if(this.result.faceTemplate && this.result.faceTemplate.length>0)
            // { 
            //    this.result.faceTemplate = "X"
            // }
            // if(this.result.fingers){
            //    this.result.fingers = this.result.fingers.map(f => 
            //    {
            //       if(f.length>0){ 
            //          return "X"
            //       } else {
            //          return ""
            //       }
            //    })
            //    // this.result.fingers.forEach(finger => {
            //    //    if(finger.length>0) finger="X"
            //    // })
            // }
            this.$forceUpdate()
         }
         console.log("Call spend time: " 
            + Math.round(new Date().getTime() - this.sendTime.getTime()))
      } ,
      'readAllUserLite-REPLY': function(payload){
         if(payload && payload.askKey == this.socketAskKey){
            payload.result.users.forEach(u => console.log(u))
            this.result = payload.result.users.length + " users"
            /*
               name: "Lê Như Huỳnh"
               cardNumber: null
               id: "T65"
               password: ""
               enabled: true
               role: 0
            */
            this.$forceUpdate()
         }
         console.log("Call spend time: " 
            + Math.round(new Date().getTime() - this.sendTime.getTime()))
      },
      'readAllUserFull-REPLY': function(payload){
         console.log("Call spend time: " 
            + Math.round(new Date().getTime() - this.sendTime.getTime()))

         if(payload && payload.askKey == this.socketAskKey){
            // payload.result.users.forEach(u => console.log(u))
            this.result = payload.result.users.length + " users"
            /*
               name: "Lê Như Huỳnh"
               cardNumber: null
               id: "T65"
               password: ""
               enabled: true
               role: 0,
               fingers: [],
               faceTemplate: ""
            */
            // this.applyDevUserToDB(payload.result.users)
            this.$forceUpdate()
         }
         
      }
   },
   methods: {
      sendPingRequest(){
         this.result = "..."
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "ping",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.devIP,
            paras: {
               name: "Mekong89",
               index: 1
            }
         })
      },
      sendUnlockRequest(){
         this.result = "..."
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "unlock",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.devIP,
            paras: {}
         })
      }, 
      sendReadUserFaceRequest(){
         this.result = "..."
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "getUserFace",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.devIP,
            paras: {
               userId: this.userID
            }
         })
      },
      sendReadOneUserRequest(){
         this.result = "..."
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "readOneUser",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.devIP,
            paras: {
               userId: this.userID
            }
         })
      },
      sendReadUsersLiteRequest(){
         this.result = "..."
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "readAllUserLite",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.devIP,
            paras: {}
         })
      },
      sendReadUsersFullRequest(){
         this.result = "..."
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "readAllUserFull",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.devIP,
            paras: {}
         })
      },
      /*
         name: "Lê Như Huỳnh"
         cardNumber: null
         id: "T65"
         password: ""
         enabled: true
         role: 0,
         fingers: [],
         faceTemplate: ""
      */
      applyDevUserToDB(devUsers){
         let self = this
         devUsers.forEach((devUser, uIndex) => {
            setTimeout(function(){
               console.log("")
               console.log("")
               console.log("")
               let dbUser = self.users.find(u => "" + u.mcvId == devUser.id)
               if(dbUser){
                  console.log("**** Match user *****")
                  console.log("DB " + dbUser.fullname )
                  console.log("Dev " + devUser.name)
                  self.saveUserWithDataFromDev(dbUser, devUser)
               } else {
                  console.log("----- Not Match user ---")
                  console.log("Dev " + devUser.name)
                  console.log("Should create new user")
                  self.createNewUserWithDataFromDev(devUser)
               }
            }, 1000*uIndex)
         })
      },
      saveUserWithDataFromDev(dbUser, devUser){
         let shouldSave = false
         if(!dbUser.zk_access) dbUser.zk_access={}
         if(!dbUser.zk_access.fingers) dbUser.zk_access.fingers = []
         if(!dbUser.zk_access.faces) dbUser.zk_access.faces = []

         if(devUser.fingers && devUser.fingers.length>0){
            if(dbUser.zk_access.fingers.length<=0){
               shouldSave = true
               devUser.fingers.forEach((finger, fIndex) => {
                  dbUser.zk_access.fingers.push({
                     index: fIndex,
                     template: finger
                  })
               })
            }
         }

         // console.log(devUser.faceTemplate)
         console.log(devUser.faceLength)

         if(devUser.faceTemplate && devUser.faceTemplate.length>0){
            dbUser.zk_access.faces = []
            // if(dbUser.zk_access.faces.length<=0){
               shouldSave = true
               dbUser.zk_access.faces.push({
                  index: 0,
                  template: devUser.faceTemplate,
                  fLength: devUser.faceLength
               })
               console.log(dbUser.zk_access.faces)
            // }
         }

         if(shouldSave){
            console.log("Save user: " + dbUser.fullname)
            this.saveUserZKAccess(dbUser._id, dbUser.zk_access)
         }
      },
      createNewUserWithDataFromDev(devUser){
         var self = this;
         var path = "https://mcvusr.duocdo.com/v1/users/create";
         let user = {
            fullname: devUser.name,
            user_code: "NV" + devUser.id,
            department: this.users[0].department,
            email: '',
            pwd: '123456cv',
            mobile: '',
            avatar: '',
            zk_access: {
               user_id: devUser.id,
               password: devUser.password,
               card_no: devUser.cardNumber,
               role: devUser.role,
               enable: devUser.enabled,
               fingers: [],
               faces: []
            },
            customer_id: this.$root.apiUserCustomId
         }

         if(user.fullname.length<3) user.fullname = user.fullname + "..."

         if(devUser.fingers && devUser.fingers.length>0){
            if(user.zk_access.fingers.length<=0){
               devUser.fingers.forEach((finger, fIndex) => {
                  user.zk_access.fingers.push({
                     index: fIndex,
                     template: finger
                  })
               })
            }
         }

         if(devUser.faceTemplate && devUser.faceTemplate.length>0){
            if(user.zk_access.faces.length<=0){
               user.zk_access.faces.push({
                  index: 0,
                  template: devUser.faceTemplate
               })
            }
         }

         console.log("User be create")
         console.log(user)

         this.axios
            .post(path, user, { headers: { "x-auth": self.token } })
            .then((response) => {
               if (response.data.status === "OK") {
                  console.log("Create user " + user.fullname + " success!");
                  
               } else {
                  console.log("Create user " + user.fullname +  " Error: " + response.data.message);
               }
            })
            .catch((err) => {
               console.log("Create user " + user.fullname + " Error: " + err.message);
            });
      },
      saveUserZKAccess(userId, zk_access) {
         this.axios.put('https://mcvusr.duocdo.com/v1/users/update/'+userId,
            { zk_access: zk_access }
            ).then(res => {
                  console.log("Save " + userId + " got " + res.data.status)
                  console.log(res.data.message)
            })
      },
      fetchUsers() {
         return new Promise((resolve) => {
            let self = this;
            let pathFetchDataUsers =
            self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId;

            self.loading = true;
            Promise.all([
            self.axios.get(pathFetchDataUsers),
            ])
            .then((responseArray) => {
               if (responseArray[0].data.status == "OK") {
                  self.users = responseArray[0].data.content.items;
                  self.users.forEach(u => {
                     u.mcvId = this.convertMaNVToCompanyId(u.user_code)
                  })

                  self.users = self.users.sort(function(a, b) {
                     return Number(a.mcvId) - Number(b.mcvId)
                  });
               }
            })
            .catch((err) => {
               console.log(err);
            })
            .finally(() => {
               // self.processTotalReport();
               self.loading = false;
               resolve()
            });
         })
      },
      convertMaNVToCompanyId(code){
         if(code && code.length>0){
            let sCode = code.replace("NV", "")
            if(sCode.includes('T')) return sCode
            else  return Number(sCode)
         }
         return -1
      },

   },
   mounted() {
      let self = this
      let beforeTime = new Date()
      this.fetchUsers().then(() => {
         console.log("Num of DB users " + self.users.length)
         console.log("Call DB users spend time: " 
            + Math.round(new Date().getTime() - beforeTime.getTime()))
      })
   },

}
</script>

<style>
   
</style>